import Vue from "vue";
import VueRouter from "vue-router";
import BillingView from "../views/BillingView.vue";
import ConfirmationView from "../views/ConfirmationView.vue";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "RCMAQ"
    },
    component: HomeView
  },
  {
    path: "/transaction/:order_id",
    name: "Billing",
    component: BillingView,
    meta: {
      title: "RCMAQ — Détails de la transaction"
    },
    props: true
  },
  {
    path: "/confirmation",
    name: "Confirmation",
    component: ConfirmationView,
    meta: {
      title: "RCMAQ – Complété!"
    },
    props: true
  },
  {
    path: "/about",
    name: "AboutView",
    meta: {
      title: "RCMAQ – À Propos"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue")
  }
];

const router = new VueRouter({
  routes
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "RCMAQ";
  next();
});

export default router;
