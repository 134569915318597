<template>
  <div class="detail-total">
    <div class="detail-total-item">
      <div class="detail-total-item--title">Total</div>
      <div class="detail-total-item--value">
        {{ total | toCurrency }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailTotalBlock",
  props: ["total"]
};
</script>

<style scoped>
.detail-total-item {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.detail-total-item--title {
  flex: 1 0 auto;
  font-size: 24px;
  font-weight: bold;
}

.detail-total-item--value {
  flex: 1 0 auto;
  font-size: 24px;
  font-weight: 900;
  text-align: right;
}
</style>
