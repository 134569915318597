<template>
  <div class="contact-form">
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <form id="contact-form" @submit.prevent="handleSubmit(onSubmitForm)">
        <div class="component-title">
          {{ $t("contact-form.title") }}
        </div>

        <div class="form--content">
          <div class="form--row">
            <validation-provider
              class="form--input"
              rules="required|max:255"
              v-slot="{ errors }"
            >
              <label for="firstname">
                {{ $t("contact-form.fields.firstname") }}
              </label>
              <input
                class="input-field"
                name="firstname"
                id="firstname"
                v-model="firstname"
                maxlength="255"
              />
              <div class="form--error">{{ errors[0] }}</div>
            </validation-provider>

            <validation-provider
              class="form--input"
              rules="required|max:255"
              v-slot="{ errors }"
            >
              <label for="lastname">
                {{ $t("contact-form.fields.lastname") }}
              </label>
              <input
                class="input-field"
                name="lastname"
                id="lastname"
                v-model="lastname"
                maxlength="255"
              />
              <div class="form--error">{{ errors[0] }}</div>
            </validation-provider>
          </div>

          <div class="form--row">
            <validation-provider
              class="form--input"
              rules="required|max:255"
              v-slot="{ errors }"
            >
              <label for="address1">
                {{ $t("contact-form.fields.address") }}
              </label>
              <input
                class="input-field"
                name="address"
                id="address1"
                v-model="address_line1"
                maxlength="255"
              />
              <input
                class="input-field"
                name="address2"
                id="address2"
                v-model="address_line2"
                maxlength="255"
              />
              <div class="form--error">{{ errors[0] }}</div>
            </validation-provider>
          </div>

          <div class="form--row">
            <validation-provider
              class="form--input"
              rules="required|max:255"
              v-slot="{ errors }"
            >
              <label for="country">
                {{ $t("contact-form.fields.country") }}
              </label>
              <select
                class="input-field select-field"
                name="country"
                id="country"
                disabled
                v-model="country"
              >
                <option value="CA" selected>
                  {{ $t("common.countries.canada") }}
                </option>
              </select>
              <div class="form--error">{{ errors[0] }}</div>
            </validation-provider>

            <validation-provider
              class="form--input"
              rules="required|max:255"
              v-slot="{ errors }"
            >
              <label for="province">
                {{ $t("contact-form.fields.province") }}
              </label>
              <select
                class="input-field select-field"
                name="province"
                id="province"
                v-model="province"
              >
                <option
                  v-for="option in $t('common.provinces')"
                  v-bind:key="option"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>
              <div class="form--error">{{ errors[0] }}</div>
            </validation-provider>
          </div>

          <div class="form--row">
            <validation-provider
              class="form--input"
              rules="required|max:255"
              v-slot="{ errors }"
            >
              <label for="city">
                {{ $t("contact-form.fields.city") }}
              </label>
              <input
                class="input-field"
                name="city"
                id="city"
                v-model="city"
                maxlength="255"
              />
              <div class="form--error">{{ errors[0] }}</div>
            </validation-provider>

            <validation-provider
              class="form--input"
              rules="required|max:32|postalCode"
              v-slot="{ errors }"
            >
              <label for="postal_code">
                {{ $t("contact-form.fields.postal_code") }}
              </label>
              <input
                class="input-field"
                name="postal_code"
                id="postal_code"
                v-model="postal_code"
                maxlength="32"
              />
              <div class="form--error">{{ errors[0] }}</div>
            </validation-provider>
          </div>

          <div class="form--row">
            <validation-provider
              class="form--input"
              rules="required|max:16|phoneNumber"
              v-slot="{ errors }"
            >
              <label for="phone_number">
                {{ $t("contact-form.fields.phone_number") }}
              </label>
              <input
                class="input-field"
                name="phone_number"
                id="phone_number"
                v-model="phone_number"
                maxlength="16"
              />
              <div class="form--error">{{ errors[0] }}</div>
            </validation-provider>

            <validation-provider
              class="form--input"
              rules="required|email|max:255"
              v-slot="{ errors }"
            >
              <label for="email">
                {{ $t("contact-form.fields.email") }}
              </label>
              <input
                class="input-field"
                name="email"
                id="email"
                v-model="email"
                maxlength="255"
              />
              <div class="form--error">{{ errors[0] }}</div>
            </validation-provider>
          </div>
        </div>

        <div class="form--row" v-if="!formSubmitted">
          <div class="form--action-container">
            <button
              type="submit"
              v-bind:disabled="isContactFormSubmitting"
              class="form--button"
            >
              {{ $t("contact-form.action") }}
            </button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { required, email, min, max } from "vee-validate/dist/rules";

// Configure validation rules
extend("phoneNumber", {
  message: "Le champ doit être un numéro de téléphone valide",
  validate(value) {
    return new Promise((resolve) => {
      let phoneValidator = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      resolve({ valid: value.match(phoneValidator) });
    });
  }
});
extend("postalCode", {
  message: "Le champ doit être un code postal valide",
  validate(value) {
    return new Promise((resolve) => {
      let postalCodeValidator = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
      resolve({ valid: value.match(postalCodeValidator) });
    });
  }
});
extend("required", {
  ...required,
  message: "Le champ est obligatoire"
});
extend("email", {
  ...email,
  message: "Le champ doit être une adresse courriel valide"
});
extend("min", {
  ...min,
  message: "Le champ doit contenir au minimum {length} caractères"
});
extend("max", {
  ...max,
  message: "Le champ doit contenir au maximum {length} caractères"
});

export default {
  name: "ContactForm",
  props: {
    populateWith: {
      type: Object,
      default: () => ({ empty: true })
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      errors: [],
      formSubmitted: false,
      isContactFormSubmitting: false,
      // Button Loading state
      color: "#495ceb80",
      size: "12px",
      // Form fields
      firstname: "",
      lastname: "",
      address_line1: "",
      address_line2: "",
      country: "CA",
      city: "",
      province: "",
      phone_number: "",
      postal_code: "",
      email: ""
    };
  },
  created() {
    if (this.populateWith != null && !this.populateWith.empty) {
      this.address_line1 = this.populateWith.address_line1;
      this.address_line2 = this.populateWith.address_line2;
      this.city = this.populateWith.city;
      this.country = this.populateWith.country;
      this.email = this.populateWith.email;
      this.firstname = this.populateWith.firstname;
      this.lastname = this.populateWith.lastname;
      this.phone_number = this.populateWith.phone_number;
      this.postal_code = this.populateWith.postal_code;
      this.province = this.populateWith.province;
    }
  },
  methods: {
    onSubmitForm: function () {
      let contactInfo = {
        address_line1: this.address_line1,
        address_line2: this.address_line2,
        city: this.city,
        country: this.country,
        email: this.email,
        firstname: this.firstname,
        lastname: this.lastname,
        phone_number: this.phone_number,
        postal_code: this.postal_code,
        province: this.province
      };

      this.formSubmitted = true;
      this.$emit("contactInfo", contactInfo);
    }
  }
};
</script>

<style></style>
