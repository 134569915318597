<template>
  <div class="confirmation">
    <div class="confirmation-wrapper">
      <div class="confirmation-container">
        <font-awesome-icon
          class="confirmation-icon"
          :icon="['far', 'check-circle']"
        />
        <div class="confirmation-title">
          {{ $t("confirmation.title") }}
        </div>
        <div class="confirmation-code">
          {{ $t("confirmation.code_title") }}
          <b>{{ payment.slug }}</b>
        </div>
        <p>
          {{ $t("confirmation.email") }}
          <b>{{ payment.email }}</b
          >.
        </p>

        <div class="confirmation-products">
          <h3>{{ $t("confirmation.details_title") }}</h3>
          {{ $tc("confirmation.elements", payment.order.products.length) }}
          <ul>
            <li v-for="product in payment.order.products" :key="product.slug">
              {{ product.quantity }}x&nbsp;:
              <b>{{ product.name }} — {{ product.price | toCurrency }}</b>
            </li>
          </ul>
          <div>
            {{ $t("confirmation.total") }}
            <b>{{ payment.amount | toCurrency }}</b>
          </div>
        </div>

        <div>
          {{ $t("confirmation.transaction_start") }}
          <b>{{ payment.statement_description }}</b>
          {{ $t("confirmation.transaction_end") }}
        </div>
      </div>

      <div class="confirmation-footer">
        {{ $t("confirmation.footer") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmationView",
  props: ["payment"],
  mounted: function () {
    if (!this.payment) {
      this.$router.replace({ name: "Home" });
    }
    this.$store.state.isPageLoading = false;
  }
};
</script>

<style scoped>
h3 {
  margin-top: 5px;
}

.confirmation {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
}

.confirmation-icon {
  font-size: 92px;
  color: #5aca14;
}

.confirmation-container {
  border: 1px solid black;
  margin: 20px auto 0;
  padding: 20px;
  background-color: #fff;
  min-width: 200px;
  text-align: center;
}

.confirmation-title {
  margin: 18px 0 28px 0;
  font-size: 24px;
  font-weight: bold;
}

.confirmation-products {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin: 20px 0;
  padding: 20px 0;
  text-align: left;
}

.confirmation-code {
  margin-bottom: 10px;
}

.confirmation-footer {
  margin: 20px 0;
  font-size: 22px;
  text-align: center;
}
</style>
