import Vue from "vue";
import App from "./App.vue";
import i18n from "./i18n";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { createProvider } from "./vue-apollo";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(far);
library.add(fas);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.filter("toCurrency", function (value) {
  if (typeof value !== "number") {
    return value;
  }

  let formatter = new Intl.NumberFormat("fr-CA", {
    style: "currency",
    currency: "CAD",
    minimumFractionDigits: 2
  });
  return formatter.format(value / 100);
});

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENV,
  release: process.env.VUE_APP_VERSION,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "rcmaq.com", /^\//]
    })
  ],
  tracesSampleRate: 1.0
});

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  i18n,
  render: (h) => h(App)
}).$mount("#app");
