<template>
  <div class="header-wrapper">
    <div class="image-wrapper">
      <img
        alt="Regroupement des Collectionneurs de Motoneiges Antiques du Québec"
        src="../assets/logo.svg"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderBlock"
};
</script>

<style scoped>
.header-wrapper {
  display: flex;
  flex: 0 0 auto;
  margin: var(--horizontal-margin);
}

@media screen and (max-width: 620px) {
  .header-wrapper {
    justify-content: center;
  }
}
</style>
