<template>
  <div class="detail-item">
    <div class="detail-item-quantity">{{ product.quantity }}x</div>

    <div class="detail-item-content">
      <div class="detail-item-title">
        {{ product.name }}
      </div>
      <div class="detail-item-subtitle">
        {{ product.note }}
      </div>
      <div class="detail-item-description">
        {{ product.description }}
      </div>
    </div>

    <div class="detail-item-price">
      {{ product.price | toCurrency }}
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailItemBlock",
  props: ["product"]
};
</script>

<style scoped>
.detail-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 25px;
}

.detail-item-quantity {
  flex: 0 0 auto;
  margin: 0 25px;
  font-size: 22px;
}

.detail-item-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.detail-item-title {
  font-size: 20px;
  font-weight: bold;
}

.detail-item-subtitle {
  margin: 2px 0 0 10px;
  font-size: 14px;
  font-style: italic;
  font-weight: normal;
}

.detail-item-description {
  margin: 5px 0 0 10px;
  font-size: 15px;
  font-weight: normal;
  text-align: justify;
}

.detail-item-price {
  flex: 0 0 auto;
  margin-left: 12px;
  font-size: 22px;
  font-weight: 900;
  text-align: right;
}
</style>
