<template>
  <div class="details">
    <div class="component-title">Détails</div>

    <div class="details-wrapper">
      <div v-for="(product, idx) in products" :key="idx">
        <DetailItemBlock v-bind:product="product" />
      </div>

      <div class="details-separator" />

      <DetailTotalBlock v-bind:total="total" />
    </div>
  </div>
</template>

<script>
import DetailItemBlock from "@/components/DetailItemBlock";
import DetailTotalBlock from "@/components/DetailTotalBlock";

export default {
  name: "DetailsBlock",
  components: { DetailTotalBlock, DetailItemBlock },
  props: ["products", "total"]
};
</script>

<style scoped>
.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.details-wrapper {
  width: 100%;
  align-self: center;
}

.details-separator {
  height: 2px;
  background: #000000;
  margin: 20px 0;
}
</style>
