<template>
  <div class="contact-info">
    <div id="contact-form">
      <div class="component-title">
        {{ $t("contact-form.title") }}
      </div>

      <div class="form--content">
        <div class="form--row">
          <div class="form--input">
            <label for="firstname">
              {{ $t("contact-form.fields.firstname") }}
            </label>
            <div class="input-field">
              {{ this.contactInfo.firstname }}
            </div>
          </div>

          <div class="form--input">
            <label for="lastname">
              {{ $t("contact-form.fields.lastname") }}
            </label>
            <div class="input-field">
              {{ this.contactInfo.lastname }}
            </div>
          </div>
        </div>

        <div class="form--row">
          <div class="form--input">
            <label for="address1">
              {{ $t("contact-form.fields.address") }}
            </label>
            <div class="input-field" v-if="this.contactInfo.address_line1">
              {{ this.contactInfo.address_line1 }}
            </div>

            <div class="input-field" v-if="this.contactInfo.address_line2">
              {{ this.contactInfo.address_line2 }}
            </div>
          </div>
        </div>

        <div class="form--row">
          <div class="form--input">
            <label for="country">
              {{ $t("contact-form.fields.country") }}
            </label>
            <div class="input-field">
              {{ $t("common.countries.canada") }}
            </div>
          </div>

          <div class="form--input">
            <label for="province">
              {{ $t("contact-form.fields.province") }}
            </label>
            <div class="input-field">
              {{ this.contactInfo.province }}
            </div>
          </div>
        </div>

        <div class="form--row">
          <div class="form--input">
            <label for="city">
              {{ $t("contact-form.fields.city") }}
            </label>
            <div class="input-field">
              {{ this.contactInfo.city }}
            </div>
          </div>

          <div class="form--input">
            <label for="postal_code">
              {{ $t("contact-form.fields.postal_code") }}
            </label>
            <div class="input-field">
              {{ this.contactInfo.postal_code }}
            </div>
          </div>
        </div>

        <div class="form--row">
          <div class="form--input">
            <label for="phone_number">
              {{ $t("contact-form.fields.phone_number") }}
            </label>
            <div class="input-field">
              {{ this.contactInfo.phone_number }}
            </div>
          </div>

          <div class="form--input">
            <label for="email">
              {{ $t("contact-form.fields.email") }}
            </label>
            <div class="input-field">
              {{ this.contactInfo.email }}
            </div>
          </div>
        </div>
      </div>

      <div class="form--row">
        <div class="form--action-container">
          <button @click="editContact($event)" class="form--reset-button">
            {{ $t("contact-preview.edit_action") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactPreview",
  props: ["contactInfo"],
  methods: {
    editContact: function (event) {
      event.preventDefault();
      this.$store.state.isContactInfoReset = true;
    }
  }
};
</script>

<style scoped>
.input-field {
  height: 20px;
  color: #808080;
  background: none;
  box-shadow: none;
  border-bottom: 2px solid #999999;
  border-radius: 0;
}
</style>
