<template>
  <div class="welcome-wrapper">
    <div class="welcome-error" v-if="error">
      <div class="welcome-error--title">
        {{ $t("common.error.title") }}
      </div>
      <div class="welcome-error--details">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "HomeView",
  data() {
    return {
      error: null,
      isLoading: false
    };
  },
  mounted: function () {
    this.$store.state.isPageLoading = true;
    this.error = null;

    let products = this.prepareProducts();
    if (products == null) {
      this.error = this.$t("common.error.generic_message");

      this.$store.state.isPageLoading = false;
    } else {
      this.createOrder(products);
    }
  },
  methods: {
    updateLoadingState(isLoading) {
      this.isLoading = isLoading;
      this.$emit("isPageLoading", this.isLoading);
    },
    prepareProducts() {
      let productParam = this.$route.query.p;
      if (productParam) {
        return [this.handleProductParam(productParam)];
      }

      return null;
    },
    handleProductParam(product, quantity = 1) {
      return {
        quantity: quantity,
        slug: product
      };
    },
    createOrder(products) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createOrderMutation($products: [OrderProduct!]!) {
              createOrder(products: $products) {
                id
                slug
              }
            }
          `,
          variables: {
            products: products
          }
        })
        .then((result) => {
          let order = result.data.createOrder;
          this.handleCreateOrderResult(order);
        })
        .catch((error) => {
          this.handleCreateOrderFailure(error);
        });
    },
    handleCreateOrderResult(order) {
      let order_id = order.slug;
      this.$store.state.isPageLoading = false;

      this.$router.replace({ name: "Billing", params: { order_id: order_id } });
    },
    handleCreateOrderFailure(error) {
      console.log(error);
      this.$store.state.isPageLoading = false;
      this.error = this.$t("order.create_failure.message");
    }
  }
};
</script>

<style scoped>
.welcome-wrapper {
  display: flex;
  flex-direction: column;
  align-self: center;
  height: 100%;
}

.welcome-error {
  border: 1px solid black;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin: auto;
  padding: 20px;
  background-color: #fff;
  min-width: 200px;
  text-align: center;
}

.welcome-error--title {
  font-size: 18px;
  font-weight: bold;
}

.welcome-error--details {
  margin-top: 10px;
  font-size: 14px;
}
</style>
