import { render, staticRenderFns } from "./PaymentInstructions.vue?vue&type=template&id=35a46d3c&scoped=true&"
import script from "./PaymentInstructions.vue?vue&type=script&lang=js&"
export * from "./PaymentInstructions.vue?vue&type=script&lang=js&"
import style0 from "./PaymentInstructions.vue?vue&type=style&index=0&id=35a46d3c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35a46d3c",
  null
  
)

export default component.exports