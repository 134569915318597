<template>
  <div class="footer-wrapper">
    <div class="footer-component footer-component--square">
      <a
        href="https://squareup.com/ca/fr"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img class="square-icon" alt="Square" src="../assets/square.png" />
      </a>
    </div>
    <div class="footer-component footer-component--copyright">
      <p class="copyright-row">©2020 - Tous droits réservés</p>
      <p class="copyright-row">
        Regroupement des Collectionneurs de Motoneiges Antiques du Québec
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterBlock"
};
</script>

<style scoped>
.footer-wrapper {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row-reverse;
  padding: var(--vertical-margin) 0;
}

.footer-component {
  flex: 1 1 auto;
  margin: 0 var(--horizontal-margin);
}

.footer-component--square {
  text-align: right;
}

.square-icon {
  width: 162px;
}

.footer-component--copyright {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-size: 12px;
}

.copyright-row {
  margin: 0 0 3px;
}

@media screen and (max-width: 620px) {
  .footer-wrapper {
    padding: calc(var(--vertical-margin) * 2) 0;
  }
}
</style>
