<template>
  <div class="payment-instructions">
    <div class="payment-instructions--description">
      {{ $t("payment-instructions.description") }}
    </div>
    <div class="payment-instructions--processing">
      {{ $t("payment-instructions.processing") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentInstructions"
};
</script>

<style scoped>
.payment-instructions--description {
  width: 100%;
  margin-top: 8px;
  font-size: 16px;
  text-align: center;
}

.payment-instructions--processing {
  width: 100%;
  margin: 16px 0;
  font-size: 16px;
  text-align: center;
}
</style>
