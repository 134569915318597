<template>
  <div class="payment-form">
    <div class="component-title">
      {{ $t("payment-form.title") }}
    </div>

    <form
      id="payment-form--component"
      class="payment-form--component"
      novalidate
    >
      <div class="form--content">
        <div id="form--card-payment"></div>

        <div class="form--row">
          <ul class="form--errors">
            <li
              class="form--error"
              v-for="error in errors"
              :key="error.message"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div class="form--row">
          <div class="form--action-container">
            <div v-if="!isFormSubmitting">
              <button
                @click="handlePaymentMethodSubmission($event)"
                class="form--button"
              >
                {{ $t("payment-form.submit_action") }}
              </button>

              <button @click="resetForm($event)" class="form--reset-button">
                {{ $t("payment-form.reset_action") }}
              </button>
            </div>

            <sync-loader
              :loading="isFormSubmitting"
              :color="color"
              :size="submitLoadingSize"
            />
          </div>
        </div>
      </div>

      <div class="payment-form--loading-wrapper" v-if="isFormLoading">
        <div class="payment-form--loading">
          <sync-loader
            :loading="isFormLoading"
            :color="loadingColor"
            :size="loadingSize"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import SyncLoader from "vue-spinner/src/SyncLoader.vue";
import gql from "graphql-tag";

const ADDRESS_VERIFICATION_FAILURE = "ADDRESS_VERIFICATION_FAILURE";
const ALREADY_PAID_ERROR = "The order is already paid.";
const CVV_FAILURE = "CVV_FAILURE";
const GENERIC_DECLINE = "GENERIC_DECLINE";
const INVALID_EXPIRATION = "INVALID_EXPIRATION";
const INVALID_VALUE_ERROR = "got invalid value";
const VALIDATION_FAILED_ERROR = "Validation failed";

export default {
  name: "PaymentForm",
  props: ["orderSlug", "contactInfo"],
  components: {
    SyncLoader
  },
  data() {
    return {
      card: null,
      errors: [],
      isFormLoading: true,
      isFormSubmitting: false,
      // Button Loading state
      color: "#495ceb80",
      loadingColor: "#e8e8ffdd",
      loadingSize: "18px",
      submitLoadingSize: "12px",
      // Form fields
      firstname: "",
      lastname: "",
      address_line1: "",
      address_line2: "",
      country: "",
      city: "",
      province: "",
      phone_number: "",
      email: ""
    };
  },
  mounted: async function () {
    let locationId = process.env.VUE_APP_SQUARE_LOCATION_ID;
    let applicationId = process.env.VUE_APP_SQUARE_APPLICATION_ID;
    let that = this;

    this.isFormLoading = true;

    const payments = window.Square.payments(applicationId, locationId);

    const cardStyle = {
      ".input-container": {
        borderColor: "#a1a1a1",
        borderRadius: "8px",
        borderWidth: "2px"
      },
      ".input-container.is-focus": {
        borderColor: "#4f4ff3"
      },
      ".input-container.is-error": {
        borderColor: "rgb(164, 0, 30)"
      },
      ".message-text": {
        color: "#555555"
      },
      ".message-icon": {
        color: "#555555"
      },
      ".message-text.is-error": {
        color: "rgb(164, 0, 30)"
      },
      ".message-icon.is-error": {
        color: "rgb(164, 0, 30)"
      },
      input: {
        backgroundColor: "#ffffff"
      },
      "input::placeholder": {
        color: "#888888"
      },
      "input.is-error": {
        color: "rgb(164, 0, 30)"
      }
    };

    this.card = await payments.card({
      style: cardStyle
    });

    await payments.setLocale(this.$i18n.locale);

    this.card
      .attach("#form--card-payment")
      .then(() => {
        that.formLoaded();
      })
      .catch((error) => {
        that.handleCreatePaymentFailure(error);
      });
  },
  beforeDestroy() {
    this.card.detach();
    this.card.destroy();
  },
  methods: {
    handlePaymentMethodSubmission: function (event) {
      event.preventDefault();
      let that = this;
      this.isFormSubmitting = true;

      const paymentMethod = this.card;
      this.tokenize(paymentMethod)
        .then((token) => {
          that.onSubmitForm(token);
        })
        .catch((error) => {
          that.handleCreatePaymentFailure(error);
        });
    },
    tokenize: async function (paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();

      if (tokenResult.status === "OK") {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
        }

        throw new Error(errorMessage);
      }
    },
    resetForm: function (event) {
      event.preventDefault();
      this.card.clear();
      this.$router.go();
    },
    formLoaded: function () {
      this.card.focus("cardNumber");
      this.isFormLoading = false;

      document.getElementById("form--card-payment").scrollIntoView({
        behavior: "smooth"
      });
    },
    onSubmitForm: function (nonce) {
      let address = {
        firstname: this.contactInfo.firstname,
        lastname: this.contactInfo.lastname,
        address_line1: this.contactInfo.address_line1,
        address_line2: this.contactInfo.address_line2,
        city: this.contactInfo.city,
        country: this.contactInfo.country,
        province: this.contactInfo.province,
        postal_code: this.contactInfo.postal_code
      };

      this.errors = [];
      this.isFormSubmitting = true;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createPaymentMutation(
              $order_slug: String!
              $nonce: String!
              $email: String!
              $phone_number: String!
              $billing_address: PaymentAddress!
              $shipping_address: PaymentAddress!
            ) {
              createPayment(
                order_slug: $order_slug
                billing_address: $billing_address
                shipping_address: $shipping_address
                email: $email
                phone_number: $phone_number
                nonce: $nonce
              ) {
                amount
                email
                receipt_url
                slug
                statement_description
                order {
                  total
                  subtotal
                  products {
                    name
                    price
                    quantity
                  }
                }
              }
            }
          `,
          variables: {
            order_slug: this.orderSlug,
            nonce: nonce,
            billing_address: address,
            shipping_address: address,
            email: this.contactInfo.email,
            phone_number: this.contactInfo.phone_number
          }
        })
        .then((result) => {
          let payment = result.data.createPayment;
          this.handleCreatePaymentResult(payment);
        })
        .catch((error) => {
          this.handleCreatePaymentFailure(error);
        });
    },
    handleCreatePaymentResult(payment) {
      this.isFormSubmitting = false;
      this.$router.push({ name: "Confirmation", params: { payment: payment } });
    },
    handleCreatePaymentFailure(error) {
      this.isFormSubmitting = false;
      this.errors = [];

      if (error.graphQLErrors) {
        this.parseGraphQLErrors(error.graphQLErrors);
      } else if (error.networkError) {
        this.$store.state.errorMessage = this.$t(
          "order.create_failure.message"
        );
      }
    },
    parseGraphQLErrors(graphQLErrors) {
      let errorString = JSON.stringify(graphQLErrors);

      if (
        errorString.includes(INVALID_VALUE_ERROR) ||
        errorString.includes(VALIDATION_FAILED_ERROR)
      ) {
        this.errors.push(this.$t("payment-form.validation_failure.message"));
      } else if (errorString.includes(ALREADY_PAID_ERROR)) {
        this.errors.push(
          this.$t("payment-form.submit_failure.already_paid_message")
        );
      } else if (errorString.includes(ADDRESS_VERIFICATION_FAILURE)) {
        this.errors.push(
          this.$t("payment-form.submit_failure.address_verification_message")
        );
      } else if (errorString.includes(CVV_FAILURE)) {
        this.errors.push(
          this.$t("payment-form.submit_failure.cvv_failure_message")
        );
      } else if (errorString.includes(GENERIC_DECLINE)) {
        this.errors.push(
          this.$t("payment-form.submit_failure.generic_decline_message")
        );
      } else if (errorString.includes(INVALID_EXPIRATION)) {
        this.errors.push(
          this.$t("payment-form.submit_failure.invalid_expiration_message")
        );
      } else {
        this.errors.push(
          this.$t("payment-form.submit_failure.generic_message")
        );
      }
    }
  }
};
</script>

<style scoped>
.payment-form .component-title {
  margin-top: 15px;
}

.payment-form--component {
  position: relative;
}

.payment-form--component .form--content #form--card-payment {
  margin-top: 10px;
}

.payment-form--loading-wrapper {
  position: absolute;
  width: calc(100% + 20px);
  height: calc(100% + 10px);
  top: -5px;
  left: -10px;
  text-align: center;
  backdrop-filter: blur(4px);
}

.payment-form--loading {
  position: absolute;
  width: 100%;
  top: calc(50% - 9px);
  text-align: center;
}
</style>
