<template>
  <div class="welcome-wrapper">
    <div class="billing--instructions">
      <PaymentInstructions />

      <div class="billing-horizontal-separator" />
    </div>

    <div class="billing-wrapper">
      <div class="billing-component">
        <ContactForm
          v-if="!contactInfo"
          :populateWith="contactInfoPrefill"
          @contactInfo="submitContactInfo"
        />

        <ContactPreview v-if="contactInfo" :contactInfo="contactInfo" />
      </div>

      <div class="billing-vertical-separator" />

      <div class="billing-component">
        <DetailsBlock
          v-bind:products="order.products"
          v-bind:total="order.total"
        />

        <div class="billing-horizontal-separator" />

        <PaymentInstructions
          v-if="!contactInfo"
          class="billing--payment-instructions"
        />

        <PaymentForm
          v-if="contactInfo"
          :contactInfo="contactInfo"
          v-bind:orderSlug="order.slug"
        />
      </div>
    </div>

    <div class="billing--disclaimer">
      {{ $t("payment-instructions.disclaimer") }}
    </div>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";
import ContactPreview from "@/components/ContactPreview";
import DetailsBlock from "@/components/DetailsBlock";
import PaymentForm from "@/components/PaymentForm";
import PaymentInstructions from "@/components/PaymentInstructions";
import gql from "graphql-tag";

export default {
  name: "BillingView",
  components: {
    ContactForm,
    ContactPreview,
    DetailsBlock,
    PaymentForm,
    PaymentInstructions
  },
  props: {
    order_id: null
  },
  apollo: {
    order: {
      query: gql`
        query FetchOrder($slug: String!) {
          order(slug: $slug) {
            completed_at
            external_id
            id
            products {
              id
              name
              description
              note
              price
              quantity
              slug
            }
            slug
            subtotal
            total
          }
        }
      `,
      variables() {
        return {
          slug: this.order_id
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isOrderLoading = isLoading || countModifier >= 0;
        this.notifyLoadingState();
      },
      fetchPolicy: "network"
    }
  },
  watch: {
    "$store.state.isContactInfoReset": function (value) {
      if (value) {
        this.contactInfoPrefill = this.contactInfo;
        this.contactInfo = null;
        this.$store.state.isContactInfoReset = false;
      }
    }
  },
  data() {
    return {
      contactInfo: null,
      contactInfoPrefill: null,
      isOrderLoading: true,
      order: {}
    };
  },
  methods: {
    submitContactInfo(contactInfo) {
      this.contactInfo = contactInfo;
    },
    notifyLoadingState() {
      this.$store.state.isPageLoading = this.isOrderLoading;
    }
  }
};
</script>

<style scoped>
.welcome-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.billing-wrapper {
  display: flex;
  flex-direction: row;
}

.billing--instructions {
  display: none;
}

.billing-component {
  flex: 1 0 calc(50% - 20px);
  margin-bottom: 10px;
}

.billing-vertical-separator {
  flex: 0 0 1px;
  background: #71717150;
  margin: 0 20px;
}

.billing-horizontal-separator {
  flex: 0 0 100%;
  height: 1px;
  background: #71717150;
  margin: 20px 0;
}

.billing--disclaimer {
  width: 100%;
  border-top: 1px solid #71717150;
  margin-top: 16px;
  padding: 16px 0;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.billing--payment-instructions {
  display: block;
}

@media screen and (max-width: 620px) {
  .billing-wrapper {
    flex-direction: column;
  }

  .billing--instructions {
    display: block;
  }

  .billing-component {
    flex: auto;
  }

  .billing-vertical-separator {
    flex: 0 0 1px;
    background: #71717150;
    margin: 20px 0;
  }

  .billing--disclaimer {
    border: none;
  }

  .billing--payment-instructions {
    display: none;
  }
}
</style>
