<template>
  <div id="app">
    <div class="app-wrapper">
      <HeaderBlock />

      <div class="content">
        <router-view v-show="!isLoading" />
      </div>

      <FooterBlock />
    </div>

    <ModalDialog v-show="hasError" @close="closeErrorModal">
      <template v-slot:header>
        <h1>{{ $t("common.error.title") }}</h1>
      </template>
      <template v-slot:body>
        <h1>{{ errorMessage }}</h1>
      </template>
    </ModalDialog>

    <div v-show="isLoading" class="loading-wrapper">
      <sync-loader :loading="isLoading" :color="color" :size="size" />
    </div>

    <div v-show="updateExists" class="update-wrapper">
      <div class="update-container">
        {{ $t("common.update.title") }}
        <br />
        <button class="update-action" @click="refreshApp()">
          {{ $t("common.update.action") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FooterBlock from "@/components/FooterBlock";
import HeaderBlock from "@/components/HeaderBlock";
import ModalDialog from "@/components/ModalDialog.vue";
import SyncLoader from "vue-spinner/src/SyncLoader.vue";

export default {
  components: {
    HeaderBlock,
    FooterBlock,
    ModalDialog,
    SyncLoader
  },
  data() {
    return {
      color: "#495ceb80",
      isModalVisible: false,
      size: "20px",
      // ServiceWorker
      refreshing: false,
      registration: null,
      updateExists: false,
      timeout: 0
    };
  },
  created() {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });

    // Refresh all open app tabs when a new service worker is installed.
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
  computed: mapState({
    errorMessage: (state) => state.errorMessage,
    hasError: (state) => state.errorMessage !== null,
    isLoading: (state) => state.isPageLoading
  }),
  methods: {
    closeErrorModal() {
      this.$store.state.errorMessage = null;
    },
    showRefreshUI(e) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;

      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    }
  }
};
</script>

<style lang="scss">
/* Imports */
@import "styles/common.css";
@import "styles/form.css";
@import "styles/variables.css";

html,
body {
  margin: 0;
  height: 100%;
  background: #ebefee;
}

#app {
  height: 100%;
  font-family: var(--default-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  display: flex;
  flex: 1 0 auto;
  width: calc(100% - 2 * var(--horizontal-margin));
  max-width: 1200px;
  padding: 0 var(--horizontal-margin);
  align-self: center;
  justify-content: center;
}

.loading-wrapper {
  position: absolute;
  height: 30px;
  width: 100%;
  top: calc(50% - 15px);
  text-align: center;
}

.update-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #5558;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.update-container {
  padding: 20px;
  background: #ffbd0f;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.update-action {
  margin-top: 15px;
  border-radius: 5px;
  border: solid 1px #1c1f69;
  background-color: #fff;
  padding: 5px 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.update-action:hover,
.update-action:focus {
  background-color: #ccc;
}
</style>
